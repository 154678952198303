export class ListString {
  data = []; 
 
}

export class MsgDetail {
  msgID = 0; 
  msgType = 0; 
  msgDescription = ''; 
 
}

export class Message {
  infoMessage = new MsgDetail(); 
  errorMessage = []; 
  hasError = false; 
  error = ''; 
 
}

export class entCredentials {
  username = ''; 
  password = ''; 
  token = []; 
  clientcode = ''; 
 
}

export class UserAdditionalDetail {
  userAdditionalInfoId = 0; 
  userSerialId = 0; 
  isTempPassword = ''; 
  currentLoginDatetime = ''; 
  lastLoginDatetime = ''; 
  defaultLandingPage = ''; 
  isAdUser = ''; 
  failedAttemptCount = 0; 
  lastFailedAttemptDatetime = ''; 
  isAccountLocked = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  urlExpiredDate = ''; 
  lastPasswordResetDate = ''; 
  pin = ''; 
  urlSendDatetime = ''; 
  organizationBranchId = 0; 
 
}

export class Group {
  groupId = 0; 
  groupName = ''; 
  beginDate = ''; 
  endDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  referenceId = 0; 
 
}

export class UserGroup {
  userGroupId = 0; 
  userSerialId = 0; 
  groupId = 0; 
  beginDate = ''; 
  endDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  referenceId = 0; 
  isActiveGroup = ''; 
  iGroup = new Group(); 
  updateSeq = ''; 
  idoObjState = ''; 
  statusDescription = ''; 
 
}

export class Address {
  addressId = 0; 
  addressReferenceId = 0; 
  addressRefernceValue = ''; 
  referenceId = 0; 
  addressTypeId = 0; 
  addressTypeValue = ''; 
  line1 = ''; 
  line2 = ''; 
  line3 = ''; 
  longitude = ''; 
  latitiude = ''; 
  msg = new Message(); 
  addressReferenceDescription = ''; 
  addressTypeDescription = ''; 
  postBoxNo = ''; 
  provinceId = 0; 
  provinceValue = ''; 
  islandId = 0; 
  islandValue = ''; 
  locationId = 0; 
  locationValue = ''; 
  areaId = 0; 
  areaValue = ''; 
  tikinaId = 0; 
  tikinaValue = ''; 
  villageId = 0; 
  villageValue = ''; 
  provinceDescription = ''; 
  islandDescription = ''; 
  locationDescription = ''; 
  areaDescription = ''; 
  tikinaDescription = ''; 
  villageDescription = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  organizationCode = ''; 
  organizationMemberId = 0; 
  villageLatitude = ''; 
  villageLongitude = ''; 
 
}

export class OrganizationBranch {
  organizationBranchId = 0; 
  organizationId = 0; 
  branchName = ''; 
  branchCode = ''; 
  branchAddressId = 0; 
  beginDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  contactPerson = ''; 
  contactNo = ''; 
  emailId = ''; 
  officeNo = ''; 
  statusDescription = ''; 
  iAddress = new Address(); 
  msg = new Message(); 
  lstUserBranchLink = []; 
 
}

export class UserBranchLink {
  organizationBranchId = 0; 
  endDate = ''; 
  startDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  userBranchLinkId = 0; 
  userSerialId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
  iOrganizationBranch = new OrganizationBranch(); 
  isActiveBranch = ''; 
  iUser = new User(); 
  statusDescription = ''; 
 
}

export class ResourcePermission {
  iResourceName = ''; 
  iPermission = ''; 
 
}

export class UserRights {
  iViewName = ''; 
  resourcePermission = []; 
 
}

export class User {
  userSerialId = 0; 
  userLoginId = ''; 
  keyword = ''; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  motherName = ''; 
  fatherName = ''; 
  dob = ''; 
  emailId = ''; 
  contactNumber = ''; 
  alternateNumber = ''; 
  genderId = 0; 
  genderValue = ''; 
  webSession = ''; 
  keyToken = ''; 
  beginDate = ''; 
  endDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  designationId = 0; 
  designationValue = ''; 
  locationId = 0; 
  locationValue = ''; 
  branchId = 0; 
  branchValue = ''; 
  employeeCode = ''; 
  departmentId = 0; 
  departmentValue = ''; 
  teamId = 0; 
  teamValue = ''; 
  referenceId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
  token = ''; 
  msg = new Message(); 
  iUserAdditionalDetail = new UserAdditionalDetail(); 
  newPassword = ''; 
  confirmPassword = ''; 
  urlDate = ''; 
  requestDate = ''; 
  contactPersonName = ''; 
  clientCode = ''; 
  lstUserGroup = []; 
  organizationId = 0; 
  memberId = 0; 
  lstUserBranchLink = []; 
  genderDescription = ''; 
  statusDescription = ''; 
  designationDescription = ''; 
  locationDescription = ''; 
  branchDescription = ''; 
  departmentDescription = ''; 
  teamDescription = ''; 
  organizationName = ''; 
  encryptedUserId = ''; 
  userRights = []; 
  userDefaultLandingPage = ''; 
  currentPassword = ''; 
  iOrganizationBranch = new OrganizationBranch(); 
 
}

export class entJwtCredentials {
  username = ''; 
  password = ''; 
  token = []; 
 
}

export class StringData {
  data = ''; 
  msg = new Message(); 
  date = ''; 
  id = 0; 
 
}

export class ConfigValue {
  configValueSerialId = 0; 
  configId = 0; 
  configConstant = ''; 
  configConstDescription = ''; 
  referenceId = 0; 
 
}

export class AssetProfile {
  assetProfileId = 0; 
  customerInfoId = 0; 
  assetTypeId = 0; 
  assetTypeValue = ''; 
  assetValue = ''; 
  assetCount = 0; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  assetTypeDescription = ''; 
  iConfigValue = new ConfigValue(); 
 
}

export class CreditProfile {
  creditProfileId = 0; 
  customerInfoId = 0; 
  creditTypeId = 0; 
  creditValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  creditTypeValue = ''; 
  iConfigValue = new ConfigValue(); 
  creditTypeDescription = ''; 
 
}

export class IncomeProfile {
  incomeProfileId = 0; 
  customerInfoId = 0; 
  incomeTypeId = 0; 
  incomeTypeValue = ''; 
  otherTypeDescription = ''; 
  incomeValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  incomeTypeDescription = ''; 
  iConfigValue = new ConfigValue(); 
 
}

export class Question {
  questionId = 0; 
  question_ = ''; 
  effectiveDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  questionTypeId = 0; 
  qustionTypeValue = ''; 
  referenceId = 0; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  statusDescription = ''; 
  qustionTypeDescription = ''; 
  questionCode = ''; 
 
}

export class QuestionResponse {
  questionResponseId = 0; 
  customerInfoId = 0; 
  questionId = 0; 
  response = ''; 
  iQuestion = new Question(); 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
 
}

export class entDocumentConfig {
  documentConfigId = 0; 
  documentCode = ''; 
  documentTypeId = 0; 
  documentTypeValue = ''; 
  effectiveDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  applicableForId = 0; 
  applicableForValue = ''; 
  isMandatory = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  documentTypeDescription = ''; 
  statusDescription = ''; 
  applicableForDescription = ''; 
 
}

export class Document {
  documentId = 0; 
  documentTypeId = 0; 
  documentTypeValue = ''; 
  fileName = ''; 
  fileSizeKb = 0; 
  fileType = ''; 
  relativePath = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  content = ''; 
  istrFileContent = ''; 
  primaryKey = 0; 
  msg = new Message(); 
  returnfiletype = ''; 
 
}

export class entOnBoardApplicationDocument {
  onboardApplicationDocumentId = 0; 
  onboardApplicationCustomerInfoId = 0; 
  onboardApplicationId = 0; 
  documentConfigId = 0; 
  documentId = 0; 
  isMandatory = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  ientDocumentConfig = new entDocumentConfig(); 
  iDocument = new Document(); 
 
}

export class entApplicationGroupOrgProductLink {
  applicationGroupOrgProductLinkId = 0; 
  onboardApplicationId = 0; 
  onboardApplicationCustomerInfoId = 0; 
  productOrganizationCode = ''; 
  productOrganizationName = ''; 
  productCode = ''; 
  productName = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  productId = 0; 
 
}

export class entCustomerApplicationData {
  productName = ''; 
  productCode = ''; 
  customerApplicationRefNo = ''; 
  customerApplicationId = 0; 
  premiumAmount = ''; 
  sumInsuredAmount = ''; 
 
}

export class entCustomer {
  alternateEmailId = ''; 
  brn = ''; 
  communityId = 0; 
  communityValue = ''; 
  contactNo = ''; 
  customerId = 0; 
  customerRefNo = ''; 
  dateOfBirth = ''; 
  drivingLicenseNo = ''; 
  emailId = ''; 
  fatherName = ''; 
  firstName = ''; 
  guardiansName = ''; 
  lastName = ''; 
  maritalStatusId = 0; 
  maritalStatusValue = ''; 
  middleName = ''; 
  mobileNo = ''; 
  motherName = ''; 
  nationalIdNo = ''; 
  nationalityId = 0; 
  nationalityValue = ''; 
  passportNo = ''; 
  permanentAddressId = 0; 
  socialSecurityNo = ''; 
  taxNo = ''; 
  tribeId = 0; 
  tribeValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  genderId = 0; 
  genderValue = ''; 
  plistentCustomerApplicationData = []; 
 
}

export class entCustomerPolicyPayout {
  customerPolicyId = 0; 
  customerPolicyPayoutId = 0; 
  computedPayoutAmount = ''; 
  percentageLoss = ''; 
  statusId = 0; 
  statusValue = ''; 
  wrmsEventInfoId = 0; 
  actualPayoutAmount = ''; 
  payoutDate = ''; 
  eventName = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  balanceAmount = ''; 
  errorMessage = ''; 
  eligibleLossPercentage = ''; 
  statusDescription = ''; 
  alreadyPaidPercentage = ''; 
  eligiblePayoutAmount = ''; 
 
}

export class entCustomerPolicy {
  coverAddressId = 0; 
  coverStartDate = ''; 
  coverTypeId = 0; 
  coverTypeValue = ''; 
  customerApplicationRefNo = ''; 
  customerPolicyId = 0; 
  effectiveDate = ''; 
  insurredAmount = ''; 
  policyNo = ''; 
  premiumAmount = ''; 
  premiumPaidDate = ''; 
  productCode = ''; 
  productName = ''; 
  receiptNo = ''; 
  riskEndDate = ''; 
  riskStartDate = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  pentCustomer = new entCustomer(); 
  pentCustomerPolicyPayout = new entCustomerPolicyPayout(); 
  plistentCustomerPolicyPayout = []; 
  policyFlag = ''; 
  enrolmentDate = ''; 
  uniqueRefNumber = ''; 
  identificationType = ''; 
  customerId = 0; 
  onboardapplicationid = 0; 
  applyingorgcode = ''; 
 
}

export class entApplication {
  applicationId = 0; 
  applyingOrganizationCode = ''; 
  initiatedDate = ''; 
  appliedDate = ''; 
  appliedBy = ''; 
  statusId = 0; 
  statusValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  applicationRefNo = ''; 
  iCustomerInfo = new CustomerInfo(); 
  applyingOrganizationName = ''; 
  preApplicationId = 0; 
  statusDescription = ''; 
  folioNo = ''; 
  policyNo = ''; 
  insuredAmount = ''; 
  policyEffectiveDate = ''; 
  lstentOnBoardApplicationDocument = []; 
  ientOnBoardApplicationDocument = new entOnBoardApplicationDocument(); 
  isignatureDocument = new Document(); 
  iPhotoDocument = new Document(); 
  preApplicationName = ''; 
  enteredOtp = ''; 
  lstentApplicationGroupOrgProductLink = []; 
  ientApplicationGroupOrgProductLink = new entApplicationGroupOrgProductLink(); 
  customerApplicationCreated = ''; 
  combinedProductOrganizationName = ''; 
  combinedProductName = ''; 
  orgCode = ''; 
  preApplicationRevisedFlag = ''; 
  signatureDocumentId = 0; 
  photoDocumentId = 0; 
  otp = 0; 
  date = ''; 
  plstentCustomerPolicy = []; 
  applyingOrganizationContactPerson = ''; 
  firstName = ''; 
 
}

export class entMemberDocument {
  memberDocumentId = 0; 
  memberId = 0; 
  documentConfigId = 0; 
  documentId = 0; 
  isMandatory = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  ientDocumentConfig = new entDocumentConfig(); 
  iDocument = new Document(); 
 
}

export class MemberDisabilityImpairments {
  checkedDisabilitiesId = 0; 
  checkedDisabilitiesValue = ''; 
  impairmentsId = 0; 
  impairmentsValue = ''; 
  isChecked = ''; 
  memberDisabilityImpairmentsId = 0; 
  memberId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
 
}

export class Member {
  memberId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  mobileNo = ''; 
  emailId = ''; 
  alternateEmailId = ''; 
  residentAddressId = 0; 
  permanentAddressId = 0; 
  dateOfBirth = ''; 
  maritalStatusId = 0; 
  maritalStatusValue = ''; 
  fatherName = ''; 
  motherName = ''; 
  guardiansName = ''; 
  nationalityId = 0; 
  nationalityValue = ''; 
  communityId = 0; 
  communityValue = ''; 
  tribeId = 0; 
  tribeValue = ''; 
  brn = ''; 
  passportNo = ''; 
  nationalIdNo = ''; 
  taxNo = ''; 
  familyMembersCount = 0; 
  agricultureLandArea = ''; 
  cropSown = ''; 
  monthlyIncomeSelf = ''; 
  monthlyIncomeFamily = ''; 
  organizationId = 0; 
  msg = new Message(); 
  iPermanetAddress = new Address(); 
  memberRefNo = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  drivingLicenseNo = ''; 
  genderId = 0; 
  genderValue = ''; 
  communityDescription = ''; 
  maritalStatusDescription = ''; 
  nationalityDescription = ''; 
  tribeDescription = ''; 
  genderDescription = ''; 
  totalInsurance = ''; 
  converageAmount = ''; 
  premiumAmount = ''; 
  lstentApplication = []; 
  ientMemberDocument = new entMemberDocument(); 
  lstentMemberDocument = []; 
  clientCode = ''; 
  organizationname = ''; 
  statusId = 0; 
  statusValue = ''; 
  statusDescription = ''; 
  bankAccountNo = ''; 
  socialSecurityNo = ''; 
  date = ''; 
  plistMemberDisabilityImpairments = []; 
 
}

export class CustomerInfo {
  customerInfoId = 0; 
  applicationId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  mobileNo = ''; 
  emailId = ''; 
  alternateEmailId = ''; 
  residentAddressId = 0; 
  permanentAddressId = 0; 
  dateOfBirth = ''; 
  maritalStatusId = 0; 
  maritalStatusValue = ''; 
  fatherName = ''; 
  motherName = ''; 
  guardiansName = ''; 
  nationalityId = 0; 
  nationalityValue = ''; 
  communityId = 0; 
  communityValue = ''; 
  tribeId = 0; 
  tribeValue = ''; 
  brn = ''; 
  passportNo = ''; 
  nationalIdNo = ''; 
  taxNo = ''; 
  familyMembersCount = 0; 
  agricultureLandArea = ''; 
  cropSown = ''; 
  monthlyIncomeSelf = ''; 
  monthlyIncomeFamily = ''; 
  iAssetProfile = new AssetProfile(); 
  iCreditProfile = new CreditProfile(); 
  lstAssetProfile = []; 
  lstCreditProfile = []; 
  iIncomeProfile = new IncomeProfile(); 
  iQuestionResponse = new QuestionResponse(); 
  iAddress = new Address(); 
  lstQuestionResponse = []; 
  msg = new Message(); 
  nationalityDescription = ''; 
  iPermanetAddress = new Address(); 
  updateSeq = ''; 
  idoObjState = ''; 
  lstIncomeProfile = []; 
  productOrganizationCode = ''; 
  organizationProductId = 0; 
  genderId = 0; 
  genderValue = ''; 
  memberId = 0; 
  productOrganizationName = ''; 
  productName = ''; 
  productCode = ''; 
  memberRefNo = ''; 
  communityDescription = ''; 
  maritalStatusDescription = ''; 
  tribeDescription = ''; 
  genderDescription = ''; 
  drivingLicenseNo = ''; 
  bankAccountNo = ''; 
  socialSecurityNo = ''; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
  iMember = new Member(); 
  stepperInfo = 0; 
  premiumId = ''; 
 
}

export class userprofile {
  userProfileId = 0; 
  userSerialId = 0; 
  userTypeId = 0; 
  userTypeValue = ''; 
  organizationId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  organizationBranchId = 0; 
  memberId = 0; 
 
}

export class DDLClass {
  id = 0; 
  code = ''; 
  description = ''; 
  name = ''; 
  constant = ''; 
  fullName = ''; 
 
}

export class DDL {
  key = ''; 
  value = []; 
 
}

export class DDLData {
  data = []; 
 
}

export class entBindDocument {
  documentId = 0; 
  orgCode = ''; 
 
}

export class LongData {
  data = 0; 
 
}

export class LaunchInfo {
  isLaunched = false; 
  launchDateTime = ''; 
 
}

export class entOtpMail {
  userLoginId = ''; 
  userSerialId = 0; 
  enteredOtp = 0; 
  userAdditionalDetailId = 0; 
  clientCode = ''; 
  emailId = ''; 
  msg = new Message(); 
  generateOtp = 0; 
 
}

export class OrganizationProductPremiumDetail {
  organizationProductPremiumDetailId = 0; 
  organizationProductId = 0; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
  changedBy = ''; 
  changedDate = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  effectiveDate = ''; 
  changedByFullName = ''; 
  premiumCode = ''; 
  statusId = 0; 
  statusValue = ''; 
  statusDescription = ''; 
  premiumAmounts = ''; 
  sumInsuredAmounts = ''; 
 
}

export class entDBHitOrganizationProduct {
  organizationProductId = 0; 
  organizationId = 0; 
  productCode = ''; 
  productName = ''; 
  benefits = ''; 
  broucherDocumentId = 0; 
  effectiveDate = ''; 
  eligibility = ''; 
  categoryDescription = ''; 
  statusDescription = ''; 
  orgProductName = ''; 
  tikinaName = ''; 
  locationName = ''; 
  lentApplication = new entApplication(); 
  lOrganizationProductPremiumDetail = new OrganizationProductPremiumDetail(); 
  endDate = ''; 
  plstOrganizationProductPremiumDetail = []; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
 
}

export class entDBHitOrganization {
  organizationId = 0; 
  organizationName = ''; 
  organizationCode = ''; 
  businessRegNo = ''; 
  companyTin = ''; 
  lstentDBHitOrganizationProduct = []; 
  ientDBHitOrganizationProduct = new entDBHitOrganizationProduct(); 
  lstentOrgBasedProduct = []; 
  contactNo = ''; 
  emailId = ''; 
  officeNo = ''; 
  statusDescription = ''; 
 
}

export class entPreapplicationProductPremiumDetailLink {
  preapplicationProductPremiumDetailLinkId = 0; 
  preapplicationGroupOrgProductLinkId = 0; 
  productCode = ''; 
  productName = ''; 
  isSelected = ''; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  premiumCode = ''; 
  premiumDetailId = 0; 
 
}

export class entPreApplicationGroupOrgProductLink {
  preapplicationGroupOrgProductLinkId = 0; 
  preapplicationGroupOrgLinkId = 0; 
  productCode = ''; 
  productName = ''; 
  orgProductName = ''; 
  isSelected = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  tikinaName = ''; 
  locationName = ''; 
  productId = 0; 
  organisationCode = ''; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
  plstentPreapplicationProductPremiumDetailLink = []; 
 
}

export class entPreApplicationGroupOrgLink {
  preapplicationGroupOrgLinkId = 0; 
  preApplicationId = 0; 
  productOrganizationCode = ''; 
  productOrganizationName = ''; 
  isSelected = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  lstentPreApplicationGroupOrgProductLink = []; 
 
}

export class lstentPreApplicationGroupOrgLink {
  ilistentPreApplicationGroupOrgLink = []; 
 
}

export class ValidateEndDate {
  productId = 0; 
  productCode = ''; 
  organizationId = 0; 
  organizationCode = ''; 
 
}

export class entCustomerPayoutReportSearch {
  productName = ''; 
  riskStartDateFrom = ''; 
  riskStartDateTo = ''; 
  riskEndDateFrom = ''; 
  riskEndDateTo = ''; 
  firstName = ''; 
  customerRefNo = ''; 
  areaValue = ''; 
  islandValue = ''; 
  provinceValue = ''; 
  tikinaValue = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  lastName = ''; 
 
}

export class entCustomerPolicySearchSet {
  customerPolicyId = 0; 
  customerId = 0; 
  customerName = ''; 
  customerRefNo = ''; 
  policyNo = ''; 
  tikina = ''; 
  coverType = ''; 
  coverStartDate = ''; 
  riskStartDate = ''; 
  riskEndDate = ''; 
  customerApplicationRefNo = ''; 
  productName = ''; 
  premiumPaidDate = ''; 
  enrolmentDate = ''; 
  computedPayoutCount = 0; 
 
}

export class entCustomerPolicySearchResult {
  plstentCustomerPolicySearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class entDemoDetails {
  name = ''; 
  emailId = ''; 
  message = ''; 
 
}

export class CustomerApplicationSearch {
  productCode = ''; 
  applicationRefNo = ''; 
  contactNo = ''; 
  emailId = ''; 
  insuredAmount = ''; 
  folioNo = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  policyNo = ''; 
  onboardDateFrom = ''; 
  onboardDateTo = ''; 
  policyEffectiveDateFrom = ''; 
  policyEffectiveDateTo = ''; 
  customerName = ''; 
  premiumAmount = ''; 
  applyingOrganizationCode = ''; 
  organizationProductId = 0; 
  productOrganizationCode = ''; 
  genderValue = ''; 
 
}

export class CustomerApplicationSearchSet {
  customerApplicationCustomerInfoId = 0; 
  customerApplicationId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  appliedOrgName = ''; 
  applicationNo = ''; 
  contactNo = ''; 
  emailId = ''; 
  onoboardDate = ''; 
  folloNo = ''; 
  policyNo = ''; 
  insuredAmount = ''; 
  policyEffectiveDate = ''; 
  applyingOrganizationCode = ''; 
  productName = ''; 
  statusDescription = ''; 
  premiumAmount = ''; 
  gender = ''; 
 
}

export class CustomerApplicationsearchresult {
  lstCustomerApplicationSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class CustomerApplicationAssetProfile {
  customerApplicationAssetProfileId = 0; 
  customerApplicationCustomerInfoId = 0; 
  assetTypeId = 0; 
  assetTypeValue = ''; 
  assetValue = ''; 
  assetCount = 0; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  assetTypeDescription = ''; 
  iConfigValue = new ConfigValue(); 
 
}

export class CustomerApplicationCreditProfile {
  customerApplicationCreditProfileId = 0; 
  customerApplicationCustomerInfoId = 0; 
  creditTypeId = 0; 
  creditValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  creditTypeValue = ''; 
  iConfigValue = new ConfigValue(); 
  creditTypeDescription = ''; 
 
}

export class CustomerApplicationIncomeProfile {
  customerApplicationCreditProfileId = 0; 
  customerApplicationCustomerInfoId = 0; 
  incomeTypeId = 0; 
  incomeTypeValue = ''; 
  otherTypeDescription = ''; 
  incomeValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  incomeTypeDescription = ''; 
  iConfigValue = new ConfigValue(); 
 
}

export class CustomerApplicationQuestionResponse {
  customerApplicationQuestionResponseId = 0; 
  customerApplicationCustomerInfoId = 0; 
  questionId = 0; 
  response = ''; 
  iQuestion = new Question(); 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
 
}

export class CustomerApplicationCustomerInfo {
  customerApplicationCustomerInfoId = 0; 
  customerApplicationId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  mobileNo = ''; 
  emailId = ''; 
  alternateEmailId = ''; 
  residentAddressId = 0; 
  permanentAddressId = 0; 
  dateOfBirth = ''; 
  maritalStatusId = 0; 
  maritalStatusValue = ''; 
  fatherName = ''; 
  motherName = ''; 
  guardiansName = ''; 
  nationalityId = 0; 
  nationalityValue = ''; 
  communityId = 0; 
  communityValue = ''; 
  tribeId = 0; 
  tribeValue = ''; 
  brn = ''; 
  passportNo = ''; 
  nationalIdNo = ''; 
  taxNo = ''; 
  familyMembersCount = 0; 
  agricultureLandArea = ''; 
  cropSown = ''; 
  monthlyIncomeSelf = ''; 
  monthlyIncomeFamily = ''; 
  iCustomerApplicationAssetProfile = new CustomerApplicationAssetProfile(); 
  iCustomerApplicationCreditProfile = new CustomerApplicationCreditProfile(); 
  lstCustomerApplicationAssetProfile = []; 
  lstCustomerApplicationCreditProfile = []; 
  iCustomerApplicationIncomeProfile = new CustomerApplicationIncomeProfile(); 
  iCustomerApplicationQuestionResponse = new CustomerApplicationQuestionResponse(); 
  iAddress = new Address(); 
  lstCustomerApplicationQuestionResponse = []; 
  msg = new Message(); 
  nationalityDescription = ''; 
  iPermanetAddress = new Address(); 
  iResidentialAddress = new Address(); 
  updateSeq = ''; 
  idoObjState = ''; 
  lstCustomerApplicationIncomeProfile = []; 
  productOrganizationCode = ''; 
  organizationProductId = 0; 
  genderId = 0; 
  genderValue = ''; 
  memberId = 0; 
  productOrganizationName = ''; 
  productName = ''; 
  productCode = ''; 
  communityDescription = ''; 
  maritalStatusDescription = ''; 
  tribeDescription = ''; 
  genderDescription = ''; 
  memberRefNo = ''; 
  drivingLicenseNo = ''; 
  bankAccountNo = ''; 
  socialSecurityNo = ''; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
 
}

export class entCustomerApplicationDocument {
  customerApplicationDocumentId = 0; 
  customerApplicationCustomerInfoId = 0; 
  customerApplicationId = 0; 
  documentConfigId = 0; 
  documentId = 0; 
  isMandatory = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  ientDocumentConfig = new entDocumentConfig(); 
  iDocument = new Document(); 
 
}

export class entCustomerApplication {
  customerApplicationId = 0; 
  applyingOrganizationCode = ''; 
  initiatedDate = ''; 
  appliedDate = ''; 
  appliedBy = ''; 
  statusId = 0; 
  statusValue = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  applicationRefNo = ''; 
  iCustomerApplicationCustomerInfo = new CustomerApplicationCustomerInfo(); 
  applyingOrganizationName = ''; 
  preApplicationId = 0; 
  statusDescription = ''; 
  folioNo = ''; 
  policyNo = ''; 
  insuredAmount = ''; 
  policyEffectiveDate = ''; 
  extractedInFile = ''; 
  lstentCustomerApplicationDocument = []; 
  isignatureDocument = new Document(); 
  iPhotoDocument = new Document(); 
  onboardApplicationId = 0; 
  cancelledReason = ''; 
  signatureDocumentId = 0; 
  photoDocumentId = 0; 
  pentCustomerPolicy = new entCustomerPolicy(); 
  applyingOrganizationContactPerson = ''; 
  certificateId = 0; 
  iCertificateDocument = new Document(); 
  ientCustomerApplicationDocument = new entCustomerApplicationDocument(); 
  isMailSent = ''; 
 
}

export class CustomerApplicationExcel {
  lstCustomerApplicationSearchSet = []; 
 
}

export class entQuestionChoices {
  id = 0; 
  description = ''; 
 
}

export class entBindQuestion {
  questionType = ''; 
  questionId = 0; 
  customerInfoId = 0; 
  questionresponseid = 0; 
  question = ''; 
  response = ''; 
  errorText = ''; 
  isMandatory = ''; 
  lstentQuestionChoices = []; 
 
}

export class entQuestionGroup {
  groupName = ''; 
  groupValue = ''; 
  lstentBindQuestion = []; 
 
}

export class Customerinfosearch {
  customerInfoId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  mobileNo = ''; 
  emailId = ''; 
  nationalityValue = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class Customerinfosearchresult {
  searchResult = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class PreApplicationSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  productOrganizationCode = ''; 
  productId = 0; 
  name = ''; 
  refNo = ''; 
  idtmBeginDateFrom = ''; 
  idtmBeginDateTo = ''; 
  idtmEndDateFrom = ''; 
  idtmEndDateTo = ''; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
 
}

export class PreApplicationSearchSet {
  preApplicationId = 0; 
  organizationName = ''; 
  productName = ''; 
  name = ''; 
  beginDate = ''; 
  endDate = ''; 
  refNo = ''; 
  premiumAmount = ''; 
  insuredAmount = ''; 
 
}

export class PreApplicationSearchResult {
  lstPreApplicationSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class OrganizationProductImages {
  documentId = 0; 
  organizationProductId = 0; 
  organizationProductImageId = 0; 
  uploadedBy = ''; 
  uploadedDate = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  iDocument = new Document(); 
 
}

export class OrganizationProduct {
  organizationProductId = 0; 
  organizationId = 0; 
  productCode = ''; 
  productName = ''; 
  benefits = ''; 
  broucherDocumentId = 0; 
  effectiveDate = ''; 
  eligibility = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  lstOrganizationProductImages = []; 
  iDocument = new Document(); 
  statusId = 0; 
  statusValue = ''; 
  categoryId = 0; 
  categoryValue = ''; 
  description = ''; 
  categoryDescription = ''; 
  statusDescription = ''; 
  lstOrganizationProductPremiumDetail = []; 
  iOrganizationProductPremiumDetail = new OrganizationProductPremiumDetail(); 
  endDate = ''; 
 
}

export class PreApplicationMemberSearchSet {
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  emailId = ''; 
  dateOfBirth = ''; 
  taxNo = ''; 
  nationalIdNo = ''; 
  memberId = 0; 
  accountNo = ''; 
  isSelected = ''; 
  brn = ''; 
  kycType = ''; 
  kycNumber = ''; 
 
}

export class PreApplicationMember {
  consentDate = ''; 
  linkedDate = ''; 
  memberConsent = ''; 
  memberId = 0; 
  preApplicationId = 0; 
  preApplicationMemberId = 0; 
  statusId = 0; 
  statusValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  consentStatusId = 0; 
  consentStatusValue = ''; 
  msg = new Message(); 
  statusDescription = ''; 
  consentStatusDescription = ''; 
  isSelected = ''; 
  lMember = new Member(); 
  ientApplication = new entApplication(); 
 
}

export class entRevisedPreApplicationLink {
  revisedPreApplicationLinkId = 0; 
  revisedPreApplicationId = 0; 
  preApplicationId = 0; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  lPreApplication = new PreApplication(); 
 
}

export class entPreAppliationMemberList {
  preApplicationMemberId = 0; 
  preApplicationId = 0; 
  memberId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  accountNo = ''; 
  contactNo = ''; 
  emailId = ''; 
  taxNo = ''; 
  brn = ''; 
  applicationStatusDescription = ''; 
  constentStatusDescription = ''; 
  consentDate = ''; 
  onboardStatusDescription = ''; 
  isSelected = ''; 
  consentStatusValue = ''; 
  statusValue = ''; 
  kycType = ''; 
  kycNumber = ''; 
 
}

export class PreApplication {
  preApplicationId = 0; 
  applyingOrganizationCode = ''; 
  applyingOrganizationName = ''; 
  productOrganizationCode = ''; 
  productOrganizationName = ''; 
  productId = 0; 
  productCode = ''; 
  productName = ''; 
  name = ''; 
  refNo = ''; 
  beginDate = ''; 
  endDate = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  lOrganizationProduct = new OrganizationProduct(); 
  lstPreApplicationMemberSearchSet = []; 
  lPreApplicationMember = new PreApplicationMember(); 
  lstentPreApplicationGroupOrgLink = []; 
  combinedProductOrganizationName = ''; 
  combinedProductName = ''; 
  lstentRevisedPreApplicationLink = []; 
  isRevised = ''; 
  sumInsuredAmount = ''; 
  premiumAmount = ''; 
  lstSelectedPreApplicationMemberList = []; 
  lstConsentPreApplicationMemberList = []; 
  lstPreApplicationMemberList = []; 
  lPreApplicationMemberList = new entPreAppliationMemberList(); 
  isEndDateOccured = ''; 
 
}

export class entRevisedPreApplicationLinkList {
  plstentRevisedPreApplicationLink = []; 
  msg = new Message(); 
 
}

export class PreApplicationMemberSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  emailId = ''; 
  dateOfBirth = ''; 
  taxNo = ''; 
  nationalIdNo = ''; 
  statusValue = ''; 
  preApplicationId = 0; 
  organizationId = 0; 
  accountNo = ''; 
  memberStatusValue = ''; 
  revisedPreApplicationId = 0; 
 
}

export class lstPreApplicationMember {
  lstPreApplicationMember = []; 
 
}

export class PreApplicationMemberSearchResult {
  lstPreApplicationMemberSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class entconstent {
  preApplicationId = 0; 
  preApplicationMemberId = 0; 
  productId = 0; 
  orgCode = ''; 
  strPreApplicationMemberId = ''; 
  strPreApplicationId = ''; 
 
}

export class ApplicationSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  productOrganizationCode = ''; 
  productId = 0; 
  idtmCreatedDateFrom = ''; 
  idtmCreatedDateTo = ''; 
  idtmAppliedDateFrom = ''; 
  idtmAppliedDateTo = ''; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  emailId = ''; 
  applicationRefNo = ''; 
  statusValue = ''; 
  organizationProductId = 0; 
  preApplicationName = ''; 
  accountNo = ''; 
  preApplicationId = 0; 
  productCode = ''; 
  initiatedDateFrom = ''; 
  submittedFromDate = ''; 
  submittedToDate = ''; 
  initiatedDateTo = ''; 
 
}

export class ApplicationSearchSet {
  accountNo = ''; 
  applyingOrganizationName = ''; 
  applicationId = 0; 
  applicationRefNo = ''; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  emailId = ''; 
  productOrganizationName = ''; 
  productName = ''; 
  statusDescription = ''; 
  initiatedDate = ''; 
  submittedDate = ''; 
  premiumAmount = ''; 
  insuredAmount = ''; 
 
}

export class ApplicationSearchResult {
  lstApplicationSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class lstAssetProfile {
  lstAssetProfile = []; 
 
}

export class lstCreditProfile {
  lstCreditProfile = []; 
 
}

export class lstIncomeProfile {
  lstIncomeProfile = []; 
 
}

export class lstQuestionResponse {
  lstQuestionResponse = []; 
 
}

export class entBindQuestionGroup {
  lstentQuestionGroup = []; 
 
}

export class entddl {
  orgCode = ''; 
  productCode = ''; 
 
}

export class entOpenApplication {
  clientCode = ''; 
  applicationId = 0; 
  customerinfoId = 0; 
 
}

export class entMemberRegistration {
  memberRegistrationId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  emailId = ''; 
  birthRegNo = ''; 
  tin = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  registeredDate = ''; 
  verificationCode = ''; 
  codeVerified = ''; 
  userSerialId = ''; 
  memberId = ''; 
  mobileFlag = ''; 
  iUser = new User(); 
  iMember = new Member(); 
  drivingLicenseNo = ''; 
  voterIdNo = ''; 
  passportNo = ''; 
  isMobileUrl = ''; 
  socialSecurityNo = ''; 
 
}

export class entMemberRegistrationReset {
  strMemberRegistrationId = ''; 
  clientCode = ''; 
  newPassword = ''; 
  confirmPassword = ''; 
  newPin = ''; 
  confirmPin = ''; 
  userserialid = ''; 
 
}

export class MemberSearch {
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  brn = ''; 
  dateOfBirth = ''; 
  passportNo = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  nationalIdNo = ''; 
  accountNo = ''; 
  taxNo = ''; 
  emailId = ''; 
  contactNo = ''; 
  location = ''; 
  village = ''; 
  statusValue = ''; 
  socialSecurityNo = ''; 
  drivingLicenseNo = ''; 
  createdDateFrom = ''; 
  createdDateTo = ''; 
 
}

export class MemberSearchSet {
  memberId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  accountNo = ''; 
  dateOfBirth = ''; 
  brn = ''; 
  taxNo = ''; 
  nationalIdNo = ''; 
  totalInsurance = ''; 
  converageAmount = ''; 
  premiumAmount = ''; 
  emailId = ''; 
  contactNo = ''; 
  location = ''; 
  village = ''; 
  status = ''; 
  identificationType = ''; 
  uniqueRefNumber = ''; 
 
}

export class Membersearchresult {
  lstMemberSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class MemberListDocument {
  memberListDocumentId = 0; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  contactNo = ''; 
  mobileNo = ''; 
  emailId = ''; 
  alternateEmailId = ''; 
  dateOfBirth = ''; 
  maritalStatusDescription = ''; 
  fatherName = ''; 
  motherName = ''; 
  guardiansName = ''; 
  nationalityDescription = ''; 
  communityDescription = ''; 
  tribeDescription = ''; 
  brn = ''; 
  passportNo = ''; 
  nationalIdNo = ''; 
  taxNo = ''; 
  familyMembersCount = 0; 
  agricultureLandArea = ''; 
  cropSown = ''; 
  monthlyIncomeSelf = ''; 
  monthlyIncomeFamily = ''; 
  accountNo = ''; 
  drivingLicenseNo = ''; 
  genderDescription = ''; 
  permanentAddLine1 = ''; 
  permanentAddLine2 = ''; 
  permanentAddLine3 = ''; 
  permanentAddLongitude = ''; 
  permanentAddLatitiude = ''; 
  permanentAddPostBoxNo = ''; 
  permanentAddProvinceDescription = ''; 
  permanentAddIslandDescription = ''; 
  permanentAddLocationDescription = ''; 
  permanentAddAreaDescription = ''; 
  permanentAddTikinaDescription = ''; 
  permanentAddVillageDescription = ''; 
  residentialAddLine1 = ''; 
  residentialAddLine2 = ''; 
  residentialAddLine3 = ''; 
  residentialAddLongitude = ''; 
  residentialAddLatitiude = ''; 
  residentialAddPostBoxNo = ''; 
  residentialAddProvinceDescription = ''; 
  residentialAddIslandDescription = ''; 
  residentialAddLocationDescription = ''; 
  residentialAddAreaDescription = ''; 
  residentialAddTikinaDescription = ''; 
  residentialAddVillageDescription = ''; 
  documentId = 0; 
  statusId = 0; 
  statusValue = ''; 
  iDocument = new Document(); 
  msg = new Message(); 
 
}

export class MemberDocumentSearch {
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  brn = ''; 
  dateOfBirth = ''; 
  passportNo = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  nationalIdNo = ''; 
  accountNo = ''; 
  taxNo = ''; 
  emailId = ''; 
  contactNo = ''; 
  location = ''; 
  village = ''; 
  statusValue = ''; 
  fileName = ''; 
 
}

export class MemberDocumentSearchSet {
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  brn = ''; 
  taxNo = ''; 
  emailId = ''; 
  contactNo = ''; 
  fileName = ''; 
  status = ''; 
  errorMsg = ''; 
 
}

export class MemberDocumentsearchresult {
  lstMemberDocumentSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class MemberLocationVerificationSearch {
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
  brn = ''; 
  dateOfBirth = ''; 
  passportNo = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  nationalIdNo = ''; 
  accountNo = ''; 
  taxNo = ''; 
  emailId = ''; 
  contactNo = ''; 
  location = ''; 
  village = ''; 
  statusValue = ''; 
  drivingLicenseNo = ''; 
  latitiude = ''; 
  longitude = ''; 
 
}

export class MemberLocationVerificationSearchSet {
  memberId = 0; 
  fullname = ''; 
  accountNo = ''; 
  dateOfBirth = ''; 
  contactNo = ''; 
  emailId = ''; 
  tikina = ''; 
  province = ''; 
  status = ''; 
  address = ''; 
  location = ''; 
  organizationName = ''; 
  gender = ''; 
  latitude = ''; 
  longitude = ''; 
  isSelected = ''; 
  identificationType = ''; 
  uniquRefNumber = ''; 
 
}

export class MemberLocationVerificationSearchresult {
  lstMemberLocationVerificationSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class SelectMemberLocationVerification {
  ilistMemberLocationVerificationSearchSet = []; 
 
}

export class DownloadCertificate {
  onBoardApplicationId = 0; 
  applyingOrganizationCode = ''; 
  productCode = ''; 
 
}

export class Bytedata {
  bytedata_ = []; 
  msg = new Message(); 
  contentType = ''; 
  error = ''; 
 
}

export class latitudeLongitudeValue {
  latitude = ''; 
  longitude = ''; 
 
}

export class lstentDBHitOrganization {
  ilstentDBHitOrganization = []; 
 
}

export class OrganizationStatusHistory {
  organizationStatusHistoryId = 0; 
  organizationId = 0; 
  statusId = 0; 
  statusValue = ''; 
  effectiveDate = ''; 
  changedBy = ''; 
  changedDate = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  changedByFullName = ''; 
  statusDescription = ''; 
 
}

export class Organization {
  organizationId = 0; 
  organizationName = ''; 
  organizationCode = ''; 
  headofficeAddressId = 0; 
  businessRegNo = ''; 
  companyTin = ''; 
  beginDate = ''; 
  statusId = 0; 
  statusValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  iOrganizationBranch = new OrganizationBranch(); 
  lstOrganizationBranch = []; 
  contactPerson = ''; 
  contactNo = ''; 
  emailId = ''; 
  officeNo = ''; 
  lstOrganizationStatusHistory = []; 
  iAddress = new Address(); 
  statusDescription = ''; 
  msg = new Message(); 
  orgTypeId = 0; 
  orgTypeValue = ''; 
  lstOrganizationProduct = []; 
  lstUser = []; 
  orgTypeDescription = ''; 
  ilistOrganization = []; 
  organizationLogoId = 0; 
  iOrganizationProduct = new OrganizationProduct(); 
  orgLogo = ''; 
  iDocument = new Document(); 
 
}

export class entinterserviceOrg {
  organizationId = 0; 
  orgCode = ''; 
  productId = 0; 
  productCode = ''; 
 
}

export class entPin {
  memberRegistrationId = 0; 
  newPin = ''; 
  confirmPin = ''; 
 
}

export class entLogin {
  userName = ''; 
  passwordFlag = ''; 
  pinFlag = ''; 
  loginPassword = ''; 
  loginPin = ''; 
 
}

export class entChangePasswordOrPin {
  userLoginId = ''; 
  currentPassword = ''; 
  newPassword = ''; 
  confirmPassword = ''; 
  currentPin = ''; 
  newPin = ''; 
  confirmPin = ''; 
 
}

export class entmemberOrganizationLink {
  memberOrganizationLinkId = 0; 
  memberId = 0; 
  organizationCode = ''; 
  organizationMemberId = 0; 
  accountNo = ''; 
  effectiveDate = ''; 
  msg = new Message(); 
  updateSeq = ''; 
  idoObjState = ''; 
  organizationName = ''; 
 
}

export class lstMemberOrganizationLink {
  ilistMemberOrganizationLink = []; 
 
}

export class entMemberService {
  memberId = 0; 
  clientCode = ''; 
 
}

export class entPreApplicationMember {
  consentDate = ''; 
  linkedDate = ''; 
  memberConsent = ''; 
  memberId = 0; 
  preApplicationId = 0; 
  preApplicationMemberId = 0; 
  statusId = 0; 
  statusValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  consentStatusId = 0; 
  consentStatusValue = ''; 
  msg = new Message(); 
  statusDescription = ''; 
  consentStatusDescription = ''; 
  isSelected = ''; 
  lMember = new Member(); 
  lPreApplication = new PreApplication(); 
  ientApplication = new entApplication(); 
 
}

export class lstentPreApplicationMember {
  lstentPreApplicationMember = []; 
 
}

export class entMemberResetPin {
  strMemberRegistrationId = 0; 
  clientCode = ''; 
  newPin = ''; 
  confirmPin = ''; 
  userserialid = 0; 
 
}

export class entMemberonboard {
  organizationCode = ''; 
  organizationName = ''; 
  productCode = ''; 
  productId = 0; 
  productName = ''; 
  memberId = 0; 
  suminsuredamount = ''; 
  premiumamount = ''; 
 
}

export class lstentApplication {
  lstentApplication = []; 
 
}

export class entorgApplication {
  memberId = 0; 
  clientCode = ''; 
 
}

export class OrganizationSearch {
  organizationName = ''; 
  organizationCode = ''; 
  businessRegNo = ''; 
  companyTin = ''; 
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class OrganizationRegistration {
  organizationRegistrationId = 0; 
  organizationName = ''; 
  businessRegNo = ''; 
  companyTin = ''; 
  statusId = 0; 
  statusValue = ''; 
  updateSeq = ''; 
  idoObjState = ''; 
  contactPerson = ''; 
  contactNo = ''; 
  emailId = ''; 
  statusDescription = ''; 
  msg = new Message(); 
  rejectedReason = ''; 
  organizationCode = ''; 
  userSerialId = 0; 
 
}

export class OrganizationSearchSet {
  organizationName = ''; 
  businessRegNo = ''; 
  companyTin = ''; 
  status = ''; 
  organizationId = 0; 
 
}

export class OrganizationSearchResult {
  lstOrganizationSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class UserSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class UserSearchSet {
  userSerialId = 0; 
  userLoginId = ''; 
  emailId = ''; 
  beginDate = ''; 
  endDate = ''; 
  status = ''; 
  firstName = ''; 
  middleName = ''; 
  lastName = ''; 
 
}

export class UserSearchResult {
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  lstUserSearchSet = []; 
 
}

export class lstUser {
  lstUser = []; 
 
}

export class lstUserGroup {
  lstUserGroup = []; 
 
}

export class lstUserBranchLink {
  lstUserBranchLink = []; 
 
}

export class OrganizationBranchSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class OrganizationBranchSearchSet {
  organizationBranchId = 0; 
  branchName = ''; 
  branchCode = ''; 
  contactPerson = ''; 
  contactNo = ''; 
  emailId = ''; 
  officeNo = ''; 
  beginDate = ''; 
  premiumAmount = ''; 
  insuredAmount = ''; 
 
}

export class OrganizationBranchSearchResult {
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  lstOrganizationBranchSearchSet = []; 
 
}

export class lstOrganizationBranch {
  lstOrganizationBranch = []; 
 
}

export class OrganizationProductSearch {
  isortcolumn = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class OrganizationProductSearchSet {
  organizationProductId = 0; 
  productName = ''; 
  productCode = ''; 
  effectiveDate = ''; 
  status = ''; 
  premiumAmount = ''; 
  insuredAmount = ''; 
  endDate = ''; 
 
}

export class OrganizationProductSearchResult {
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  lstOrganizationProductSearchSet = []; 
 
}

export class lstOrganizationProduct {
  lstOrganizationProduct = []; 
 
}

export class lstOrganizationProductImages {
  lstOrganizationProductImages = []; 
 
}

export class entreportcommon {
  orgcode = ''; 
 
}

export class entReportParam {
  coperativeCode = ''; 
  insuranceCode = ''; 
  firstName = ''; 
  lastName = ''; 
  dobFromDate = ''; 
  dobToDate = ''; 
  productCode = ''; 
  applicationRefNo = ''; 
  memberStatus = ''; 
  applicationStatus = ''; 
  gender = ''; 
  isunadmin = ''; 
  createdDateFrom = ''; 
  createdDateTo = ''; 
 
}

export class entMemberRegistrationReport {
  onboardApplicationId = 0; 
  memberRefNo = ''; 
  memberName = ''; 
  age = 0; 
  gender = ''; 
  address = ''; 
  memberStatus = ''; 
  productName = ''; 
  productOrganization = ''; 
  applicationRefNo = ''; 
  applicationStatus = ''; 
  onboardedDate = ''; 
  kycType = ''; 
  kycNumber = ''; 
  contactNo = ''; 
  dateOfBrith = ''; 
  createdDate = ''; 
  emailid = ''; 
  coverLimit = ''; 
  coverPremium = ''; 
  tikina = ''; 
  village = ''; 
 
}

export class lstentMemberRegistrationReport {
  ilstentMemberRegistrationReport = []; 
  organizationname = ''; 
  orgcode = ''; 
 
}

export class entWrmsEventInfoDetail {
  threeDaysRf = 0; 
  coverTypeId = 0; 
  coverTypeValue = ''; 
  distance = 0; 
  fijiDate = ''; 
  gmtDate = ''; 
  islandId = 0; 
  islandValue = ''; 
  latitude = ''; 
  longitude = ''; 
  lossPercentage = ''; 
  policyYear = 0; 
  provinceId = 0; 
  provinceValue = ''; 
  regionId = 0; 
  regionValue = ''; 
  tikinaId = 0; 
  tikinaValue = ''; 
  time = 0; 
  windSpeed = 0; 
  wrmsEventInfoDetailId = 0; 
  wrmsEventInfoId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
 
}

export class entWrmsEventInfo {
  eventEndDate = ''; 
  eventName = ''; 
  eventStartDate = ''; 
  lossCalculationDate = ''; 
  wrmsEventInfoId = 0; 
  updateSeq = ''; 
  idoObjState = ''; 
  msg = new Message(); 
  lstentWrmsEventInfoDetail = []; 
 
}

export class entWRMSEventInfoSearch {
  eventName = ''; 
  eventStartDateFrom = ''; 
  eventStartDateTo = ''; 
  eventEndDateFrom = ''; 
  eventEndDateTo = ''; 
  islandValue = ''; 
  provinceValue = ''; 
  tikinaValue = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  loginUserName = ''; 
  currentDate = ''; 
 
}

export class entWRMSEventInfoSearchSet {
  eventName = ''; 
  island = ''; 
  tikina = ''; 
  coverType = ''; 
  time = ''; 
  fijiDate = ''; 
  latitude = ''; 
  longitude = ''; 
  distance = 0; 
  windSpeed = 0; 
  days3Rf = ''; 
  lossPercentage = ''; 
  eventStartDate = ''; 
  eventEndDate = ''; 
  eligibleLossPercentage = ''; 
  customerRefNo = ''; 
  policyNo = ''; 
  windLoss = ''; 
  alreadyPaidPercentage = ''; 
  eligiblePayoutAmount = ''; 
 
}

export class entWRMSEventInfoSearchresult {
  lstentWRMSEventInfoSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class entCustomerPolicySearch {
  customerRefNo = ''; 
  customerApplicationRefNo = ''; 
  policyNo = ''; 
  coverTypeValue = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  statusValue = ''; 
 
}

export class entCustomerPayoutSearch {
  customerRefNo = ''; 
  customerApplicationRefNo = ''; 
  policyNo = ''; 
  coverTypeValue = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
 
}

export class entCustomerPayoutSearchSet {
  customerId = 0; 
  customerName = ''; 
  customerRefNo = ''; 
  policyNo = ''; 
  tikina = ''; 
  coverType = ''; 
  coverStartDate = ''; 
  riskStartDate = ''; 
  riskEndDate = ''; 
  payoutAmount = ''; 
  eventName = ''; 
  percentageLoss = ''; 
  actualPayoutAmount = ''; 
  payoutDate = ''; 
  insurredAmount = ''; 
  balanceAmount = ''; 
  eligibleLossPercentage = ''; 
  eligiblePayoutAmount = ''; 
  customerPolicyPayoutId = 0; 
 
}

export class entCustomerPayoutSearchResult {
  plstentCustomerPayoutSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}

export class Kyc {
  data = ''; 
  msg = new Message(); 
  kfcConstant = ''; 
 
}

export class entCustomerApplicationDataList {
  plstentCustomerApplicationData = []; 
 
}

export class entWrmsDocumentSearch {
  fileName = ''; 
  downloadedBy = ''; 
  downloadedDateFrom = ''; 
  downloadedDateTo = ''; 
  isortorder = false; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
  orderByColumnName = ''; 
  ascending = false; 
  referenceType = ''; 
 
}

export class entWrmsDocumentSearchSet {
  payoutDocumentId = 0; 
  fileName = ''; 
  fileType = ''; 
  memberCount = 0; 
  createdBy = ''; 
  createdDate = ''; 
  status = ''; 
  referenceId = 0; 
  successCount = ''; 
  failedCount = ''; 
  totalCount = ''; 
 
}

export class entWrmsDocumentSearchResult {
  plstentWrmsDocumentSearchSet = []; 
  totalCount = 0; 
  pageSize = 0; 
  pageNumber = 0; 
 
}




