import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { DataService } from "src/app/common/services/data/data.service";
// import { ApiEndpoint } from "./api.endpoints";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(public data: DataService) {}

gettoken(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('authenticate/gettoken', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
authenticateuser(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/authenticateuser', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getJWT(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/GenerateJWT', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
authenticateitguser(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/authenticateitguser', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
forgotuserpassword(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/forgotuserpassword', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
forgotuserid(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/forgotuserid', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewuserprofile(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('authenticate/createnewuserprofile', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
registeruserprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/registeruserprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
resetuserpassword(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/resetuserpassword', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getuserdetailsfromurl(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/getuserdetailsfromurl', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveuserpassword(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/saveuserpassword', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
decryptuserdata(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/decryptuserdata', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
changeuserpassword(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('authenticate/ChangeUserPassword', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getcustomerinitialdataaddress(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/getcustomerinitialdataaddress', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
createnewcustomeraddress(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/createnewcustomeraddress', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
savecustomeraddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/savecustomeraddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getquestion(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/getquestion', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveaddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/saveaddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadislandbasedonprovince(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadislandbasedonprovince', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadlocationbasedonprovince(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadlocationbasedonprovince', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadareabasedonlocation(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadareabasedonlocation', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadtikinabasedonprovince(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadtikinabasedonprovince', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadvillagebasedontikina(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadvillagebasedontikina', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
loadactivecoperativeorganization(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/loadactivecoperativeorganization', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
openimage(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/images/{DocumentId}/{OrgCode}', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
opendocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/opendocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
opendocumentMobile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/opendocumentMobile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadDefaultLandingPage(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loaddefaultlandingpage', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getLaunchInfo(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/getlaunchinfo', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
setLaunchInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/setlaunchinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
openorganizationlogo(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/logo/{OrgCode}', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
downloaddocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/downloaddocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
sendotpforgetpinorpasswordorusername(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/sendotpforgetpinorpasswordorusername', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
validateotpforgetpinorpasswordorusername(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/validateotpforgetpinorpasswordorusername', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openorgbasedproductdetails(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/openorgbasedproductdetails', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openperapplicationgrouporglink(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/openperapplicationgrouporglink', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadalltikina(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadalltikina', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadtikinabasedonprovinceanddate(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/loadtikinabasedonprovinceanddate', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
checkIfProductEndDateOccurs(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/CheckIfProductEndDateOccurs', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openaddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/openaddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomerPayoutReportInCoOperatives(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/CreateCustomerPayoutReportInCoOperatives', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
searchCustomerPayoutReportInCoOperatives(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/SearchCustomerPayoutReportInCoOperatives', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
loadactiveinsurnaceorganization(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('common/loadactiveinsurnaceorganization', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
sumbitDemoScheduleDetails(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('common/SumbitDemoScheduleDetails', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getcustomerapplictioninitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customerapplication/getcustomerapplictioninitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getcustomerapplicationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customerapplication/getcustomerapplicationsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
customerapplicationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/customerapplicationsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openCustomerapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/openCustomerapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
downloadCustomerDetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/downloadCustomerDetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
downloadSearchBasedCustomerDetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/DownloadSearchBasedCustomerDetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getAllQuestionBasedOnGroupForCustomerApplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/getallquestionbasedongroupforcustomerapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
cancelCustomerApplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/cancelcustomerapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadCertificateForCustomerApplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/UploadCertificateForCustomerApplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
updateCustomerApplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/UpdateCustomerApplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
updateCACustomerInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/UpdateCACustomerInfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
updateCustomerApplicationDocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customerapplication/UpdateCustomerApplicationDocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getcustomerinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getcustomerinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
createnewcustomer(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/createnewcustomer', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
deletecustomerinfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deletecustomerinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
opencustomerinfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/opencustomerinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getcustomerinfosearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getcustomerinfosearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
customerinfosearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/customerinfosearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createnewasset(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/createnewasset', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveassetprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveassetprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openassetprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openassetprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteassetprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deleteassetprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createnewcredit(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/createnewcredit', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savecreditprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savecreditprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
opencreditprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/opencreditprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deletecreditprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deletecreditprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createnewincome(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/createnewincome', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveincomeprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveincomeprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openincomeprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openincomeprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteincomeprofile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deleteincomeprofile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewquestionresponse(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/createnewquestionresponse', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
savecustomerinfopermanentaddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savecustomerinfopermanentaddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
updateCustomerInfoPermanentAddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/UpdateCustomerInfoPermanentAddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getpreapplicationinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getpreapplicationinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
loadallpreapplicationgroups(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadallpreapplicationgroups', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getproductnamebasedonorgid(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/getproductnamebasedonorgid', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openproductdetailsbyclientcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openproductdetailsbyclientcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadpreapplicationproductbyorgcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadpreapplicationproductbyorgcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getpreapplicationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getpreapplicationsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
preapplicationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/preapplicationsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openpreapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openpreapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewpreapplication(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/createnewpreapplication', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
createNewRevisedPreApplication(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/CreateNewRevisedPreApplication', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
loadAllRevisedPreApplicationGroups(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/LoadAllRevisedPreApplicationGroups', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savepreapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savepreapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
preapplicationmembersearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/preapplicationmembersearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
linkuppreapplicationmemberlist(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/linkuppreapplicationmemberlist', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
delinkpreapplicationmemberlist(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/delinkpreapplicationmemberlist', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
agreepreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/agreepreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
disagreepreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/disagreepreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
cancelpreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/cancelpreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deletepreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deletepreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
viewonboardapplicationfrompreapplicaiton(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/viewonboardapplicationfrompreapplicaiton', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openpreapplicationforconsent(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openpreapplicationforconsent', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
resendmailconsent(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/resendmailconsent', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
agreeMemberConsent(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/agreeMemberConsent', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
disagreememberconsent(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/disagreememberconsent', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
bulkonboadrdapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/bulkonboadrdapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadselectedpreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadselectedpreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadconsentpreapplicationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadconsentpreapplicationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
checkIfEndDateOccursForSelectedProductsByPreAppplicationId(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/checkIfEndDateOccursForSelectedProductsByPreAppplicationId', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deletePreApplicationByPreApplicationId(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/DeletePreApplicationByPreApplicationId', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/createapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getapplicationinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getapplicationinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getapplicationinitialdataforsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getapplicationinitialdataforsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getapplicationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/getapplicationsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
applicationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/applicationsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewapplication(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('customeronboarding/createnewapplication', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
submitapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/submitapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
cancelledapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/cancelledapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/openapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadapplicationdocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/uploadapplicationdocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteapplicationdocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/deleteapplicationdocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savecustomerinfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savecustomerinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveOnboardPersonalInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/SaveOnboardPersonalInfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveONboardKYCInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/SaveONboardKYCInfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveOnboardCommunicationInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/SaveOnboardCommunicationInfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveListOfAsset(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/SaveListOfAsset', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofcreditdetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savelistofcreditdetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofincomedetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savelistofincomedetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofquestionresponse(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savelistofquestionresponse', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getallquestionbasedongroupforapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/getallquestionbasedongroupforapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savequestionresponse(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/savequestionresponse', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadonboardapplicationproductbyorgcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadonboardapplicationproductbyorgcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadpreapplicationbasedorgcodeandproduct(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/loadpreapplicationbasedorgcodeandproduct', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveonboardapplicationsignature(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveonboardapplicationsignature', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveonboardapplicationphoto(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveonboardapplicationphoto', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveotpforonboardapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/saveotpforonboardapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getallquestionresponsebasedonclientcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('customeronboarding/getallquestionresponsebasedonclientcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getmemberregstrationinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/getmemberregstrationinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
createnewmemberregistration(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/createnewmemberregistration', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
savememberregistraion(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/savememberregistraion', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savememberresetpasswordandpin(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/savememberresetpasswordandpin', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openmemberregistration(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/openmemberregistration', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getmemberinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/getmemberinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
createnewmember(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/createnewmember', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
savemember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/savemember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getmembersearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/getmembersearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
membersearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/membersearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/openmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deletemember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/deletemember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savememberpermanentaddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/savememberpermanentaddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadmemberlistdocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/uploadmemberlistdocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
downloadsamplememberexcel(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/downloadsamplememberexcel', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getMemberDocumentSearchInitialData(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/getmemberdocumentsearchinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getmemberdocumentsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/getmemberdocumentsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
memberdocumentsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/memberdocumentsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getmemberloactionverificationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/GetMemberLoactionVerificationSearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
memberloactionverificationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/MemberLoactionVerificationSearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
verifyListOfMemberLocation(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/VerifyListOfMemberLocation', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
verifyMemberLocation(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/VerifyMemberLocation', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
pendingverificationmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/pendingverificationmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
bulkpendingverificationmember(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/bulkpendingverificationmember', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
downloadsearchbasedlocationverification(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/downloadsearchbasedlocationverification', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getLatitudeLongitudeByTikinaValue(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/GetLatitudeLongitudeByTikinaValue', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getLocationVerificationInitialData(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('member/GetLocationVerificationInitialData', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
downloadCertificateByCooperatives(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/DownloadCertificateByCooperatives', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
downloadCertificateByCooperativesMoblie(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/DownloadCertificateByCooperativesMoblie', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getLatitudeLongitudeByVillageValue(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('member/GetLatitudeLongitudeByVillageValue', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
loadallinsuranceproviderorganisation(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('mobile/loadallinsuranceproviderorganisation', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
openallproductbasedonorganization(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/openallproductbasedonorganization', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openproductdetailsbyproductcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/openproductdetailsbyproductcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
validateverificationcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/validateverificationcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savememberregpin(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/savememberregpin', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
memberlogin(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/memberlogin', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
changeuserloginpassword(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/changeuserloginpassword', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
changeuserloginpin(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/changeuserloginpin', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadorganizationusingmemberid(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/loadorganizationusingmemberid', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadapplicationusingmemberid(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/loadapplicationusingmemberid', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openconsent(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/openconsent', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadpreapplicationbasedonmemberidandclientcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/loadpreapplicationbasedonmemberidandclientcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
agreememberconsentformobile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/agreememberconsentformobile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
disagreememberconsentformobile(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/disagreememberconsentformobile', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveresetpasswordformember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/saveresetpasswordformember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveresetpinformember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/saveresetpinformember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createapplicationfrommember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/createapplicationfrommember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadapplicationlistforloginmember(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/loadapplicationlistforloginmember', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openallproductbasedapplication(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/openallproductbasedapplication', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openapplicationusingclientcode(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('mobile/openapplicationusingclientcode', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getorganizationregstrationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationregstrationsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
organizationregstrationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/organizationregstrationsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createneworganizationregistration(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createneworganizationregistration', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
openorganizationregistration(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/openorganizationregistration', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveorganizationregistraion(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationregistraion', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
approveorrejectorganizationregistraion(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/approveorrejectorganizationregistraion', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getorganizationinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getorganizationsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
organizationsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/organizationsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createneworganization(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createneworganization', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveorganization(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganization', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openorganization(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/openorganization', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getuserinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getuserinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getusersearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getusersearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
usersearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/usersearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openuser(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/openuser', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewuser(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createnewuser', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveorganizationuser(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationuser', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveuser(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveuser', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
addlistofusergroup(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/addlistofusergroup', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
addlistofuserbranchlink(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/addlistofuserbranchlink', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveusergroup(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveusergroup', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofusergroup(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savelistofusergroup', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveuserbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveuserbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofuserbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savelistofuserbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getorganizationbranchinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationbranchinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getorganizationbranchsearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationbranchsearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
organizationbranchsearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/organizationbranchsearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createneworganizationbranch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createnewOrganizationBranch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveorganizationbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savebranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savebranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteorganizationbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/deleteorganizationbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openorganizationbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/openorganizationbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savebranchaddress(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savebranchaddress', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
adduserinorganizationbranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/adduserinorganizationbranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistofuserfrombranch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savelistofuserfrombranch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getorganizationproductinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/getorganizationproductinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
 
getOrganizationProductSearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/GetOrganizationProductSearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
organizationProductSearch(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/OrganizationProductSearch', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createneworganizationproduct(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createneworganizationproduct', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveorganizationproduct(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationproduct', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveproduct(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveproduct', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteorganizationproduct(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/deleteorganizationproduct', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openorganizationproduct(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/openorganizationproduct', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveorganizationproductimages(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationproductimages', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteorganizationproductimages(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/deleteorganizationproductimages', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
saveorganizationproductdocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationproductdocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
deleteorganizationproductdocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/deleteorganizationproductdocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
savelistoforganizationproductimage(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/savelistoforganizationproductimage', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openOrgformVariousDB(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/OpenOrgformVariousDB', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewproductPremiumdetails(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('organization/createnewproductPremiumdetails', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveorganizationproductpremiumdetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/saveorganizationproductpremiumdetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openProductPremiumDetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('organization/OpenProductPremiumDetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getmemberreportinitialdata(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Report/getmemberreportinitialdata', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
createnewmemberreport(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Report/createnewmemberreport', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
generatememberregistrationreport(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Report/generatememberregistrationreport', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
generateMemberRegistrationExcel(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Report/GenerateMemberRegistrationExcel', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomer(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateCustomer', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveCustomer(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SaveCustomer', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openCustomer(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/OpenCustomer', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomerPolicy(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateCustomerPolicy', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveCustomerPolicy(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SaveCustomerPolicy', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openCustomerPolicy(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/OpenCustomerPolicy', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomerPolicyPayout(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateCustomerPolicyPayout', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveCustomerPolicyPayout(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SaveCustomerPolicyPayout', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createWrmsEventInfo(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateWrmsEventInfo', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveWrmsEventInfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SaveWrmsEventInfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createWrmsEventInfoDetail(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateWrmsEventInfoDetail', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
saveWrmsEventInfoDetail(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SaveWrmsEventInfoDetail', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createnewwrmseventinfosearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/createnewwrmseventinfosearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
searchwrmseventinfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/searchwrmseventinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
getwrmseventinfoinitialdata(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/getwrmseventinfoinitialdata', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
downloadwrmseventinfo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/downloadwrmseventinfo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomerPolicySearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateCustomerPolicySearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
searchCustomerPolicy(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SearchCustomerPolicy', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createCustomerPayoutSearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreateCustomerPayoutSearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
searchCustomerPayout(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SearchCustomerPayout', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
downloadSearchBasedCustomerPayout(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/DownloadSearchBasedCustomerPayout', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
getCustomerBasedOnCustomerProofNo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/GetCustomerBasedOnCustomerProofNo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadCustomerPolicyListDocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/UploadCustomerPolicyListDocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadCustomerPolicyPayoutList(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/UploadCustomerPolicyPayoutList', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getCustomerApplicationListBasedOnCustomerProofNo(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/GetCustomerApplicationListBasedOnCustomerProofNo', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
downloadPolicyForWRMS(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/DownloadPolicyForWRMS', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
uploadWRMSDataWithPolicy(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/UploadWRMSDataWithPolicy', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
openWRMSDocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/OpenWRMSDocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
loadWRMSDocumnentsBasedOnWRMSScreenType(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/LoadWRMSDocumnentsBasedOnWRMSScreenType', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
createPayoutDocumentSearch(options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data.getData('Wrms/CreatePayoutDocumentSearch', options).pipe(
      finalize(() => this.data.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? '' : this.data.errorMethod(err);
        return throwError(err);
      })
    );
}
  
searchPayoutDocument(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/SearchPayoutDocument', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getCustomerPolicyListDocumentByDocumentId(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/GetCustomerPolicyListDocumentByDocumentId', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getWrmsInfoListDocumentByDocumentId(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/GetWrmsInfoListDocumentByDocumentId', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
  
getCustomerPolicyPayoutListDocumentbydocumentId(body, options?): Observable<any> {
    this.data.serviceStarted();
    options === undefined
      ? (options = this.data.defaultOptions)
      : (options = this.data.setOptions(options));
    return this.data
      .postData('Wrms/getCustomerPolicyPayoutListDocumentbydocumentId', body, options)
      .pipe(
        finalize(() => this.data.serviceCompleted()),
        catchError((err) => {
          options.hideErrorMethod ? '' : this.data.errorMethod(err);
          return throwError(err);
        })
      );
}
 
}
